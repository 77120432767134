import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Header from '../components/global/header';
import SideMenu from '../components/global/sideMenu';
import Club from '../components/golfClub/club';
import Course from '../components/golfClub/course';
import NoticeBoard from '../components/golfClub/noticeBoard';
import TeeBox from '../components/golfClub/teeBox';
import AdminMenu from '../components/settings/adminMenu';
import AdminUser from '../components/settings/adminUser';
import EmailTemplate from '../components/settings/emailTemplate';
import AdminRole from '../components/settings/adminRole';
import IOSUpdate from '../components/appControl.js/iOSUpdate';
import AdminUpdate from '../components/appControl.js/adminUpdate';
import AndroidUpdate from '../components/appControl.js/androidUpdate';
import AndroidService from '../components/appControl.js/androidService';
import IOSService from '../components/appControl.js/iOSService';
import AdminService from '../components/appControl.js/adminService';
import HolidayCalender from '../components/golfClub/holidayCalender';
import TournamentCalender from '../components/golfClub/tournamentCalender';
import Users from '../components/users/users';
import Event from '../components/golfClub/event';
import ShotgunBlock from '../components/teeTime/shotgunBlock';
import ShotgunCalender from '../components/teeTime/shotgunCalender';
import ShotgunMaster from '../components/teeTime/shotgunMaster';
import ShotgunTemplate from '../components/teeTime/shotgunTemplate';
import StaggeredBlock from '../components/teeTime/staggeredBlock';
import StaggeredMaster from '../components/teeTime/staggeredMaster';
import StaggeredTemplate from '../components/teeTime/staggeredTemplate';
import EventRegistration from '../components/golfClub/eventRegistration';
import CourseClosed from '../components/golfClub/courseClosed';
import HoleInOne from '../components/golfClub/holeInOne';
import AdvertisementMaster from '../components/advertisment/advertisementMaster';
import TeeTimeBlocking from '../components/teeTime/teeTimeBlocking';
import TeeTimePublish from '../components/teeTime/teeTimePublish';
import BookingSheet from '../components/teeTime/bookingSheet';
import Guest from '../components/users/guest';
import ConciergeMaster from '../components/golfClub/conciergeMaster';
import CancellationSheet from '../components/teeTime/cancellationSheet';
import Feedback from '../components/golfClub/feedback';
import AdvertisementPost from '../components/advertisment/advertisementPost';
import Emailer from '../components/golfClub/emailer';
import TeeTimeNoShowReport from '../components/teeTime/teeTimeNoShowReport';
import SportsMaster from '../components/sports/sportsMaster';
import SportsReport from '../components/sports/sportReport';
import ScorecardMaster from '../components/golfClub/scoreCardMaster';
import InvoiceTemplate from '../components/settings/invoiceTemplate';
import PaymentReport from '../components/payment/paymentReport';
import MatMaster from '../components/mat/matMaster';
import MatTimeMaster from '../components/mat/matTimeMaster';
import MatTemplate from '../components/mat/matTemplate';
import MatBlocking from '../components/mat/matBlocking';
import MatBookingSheet from '../components/mat/matBookingSheet';
import MatCancellationSheet from '../components/mat/matCancellationSheet';
import FacilityMaster from '../components/sports/facilityMaster';
import TimeMaster from '../components/sports/timeMaster';
import SportsTemplate from '../components/sports/sportsTemplate';
import SportsPublish from '../components/sports/sportsPublish';
import SportsBookingSheet from '../components/sports/sportsBookingSheet';
import SportsCancellationSheet from '../components/sports/sportsCancellationSheet';
import AffiliateClub from '../components/golfClub/affiliateClub';
import MatPublish from '../components/mat/matPublish';
import SportsBlocking from '../components/sports/sportsBlocking';

const Admin = (props) => {

	const [headerTitle,                   setHeaderTitle]                      = useState('');
    const [menuID,                        setMenuID]                           = useState(null);

    const toggleState = (title, menuID) => {
		setHeaderTitle(title);
        setMenuID(menuID);
	};

    return (  
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <SideMenu menuID={menuID} title={toggleState} />
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                
                <Header onLogout={props.onAuthStateChange} title={headerTitle} /> 
                {menuID && <div style={{ paddingTop: '0px' }} id="kt_content" className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0">
                    <Switch>
                    {/*Mat*/}
                    <Route path="/mat-sheet" exact>
                        <MatBookingSheet
                            title={toggleState}
                        />
                    </Route>
                    <Route path="/mat-publish" exact>
                        <MatPublish
                            title={toggleState}
                        />
                    </Route>
                    <Route path="/mat-cancel-sheet" exact>
                        <MatCancellationSheet
                            title={toggleState}
                        />
                    </Route>
                    <Route path="/mat-master" exact>
                        <MatMaster
                            title={toggleState}
                        />
                    </Route>
                    <Route path="/mat-time-master" exact>
                        <MatTimeMaster
                            title={toggleState}
                        />
                    </Route>
                    <Route path="/mat-template" exact>
                        <MatTemplate
                            title={toggleState}
                        />
                    </Route>
                    <Route path="/mat-block" exact>
                        <MatBlocking
                            title={toggleState}
                        />
                    </Route>
                    {/* Settings */}
                        <Route path="/admin-user" exact>
                            <AdminUser
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/admin-role" exact>
                            <AdminRole
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/admin-menu" exact>
                            <AdminMenu
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/email-template" exact>
                            <EmailTemplate
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/invoice-template" exact>
                            <InvoiceTemplate
                                title={toggleState} 
                            />
                        </Route>
                    {/* Settings End*/}

                    {/* Golf Club */}
                        <Route path="/notice-board" exact>
                            <NoticeBoard
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/event" exact>
                            <Event
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/event-registration" exact>
                            <EventRegistration
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/holiday-calender" exact>
                            <HolidayCalender
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/tournament-calender" exact>
                            <TournamentCalender
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/hole-in-one" exact>
                            <HoleInOne
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/feedback" exact>
                            <Feedback
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/club" exact>
                            <Club
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/teebox" exact>
                            <TeeBox
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/course-closed" exact>
                            <CourseClosed
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/course" exact>
                            <Course
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/scorecard-master" exact>
                            <ScorecardMaster
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/concierge-master" exact>
                            <ConciergeMaster
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/affiliate-club" exact>
                            <AffiliateClub 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/admin-emailer" exact>
                            <Emailer
                                title={toggleState} 
                            />
                        </Route>
                    {/* Golf Club End*/}

                    {/* Advertisement */}
                        <Route path="/advertisement-master" exact>
                            <AdvertisementMaster
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/advertisement-post" exact>
                            <AdvertisementPost
                                title={toggleState} 
                            />
                        </Route>
                    {/* Advertisement End */}
            
                    {/* Tee Time */}
                        <Route path="/tee-sheet">
                            <BookingSheet 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/tee-cancel-sheet">
                            <CancellationSheet 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/tee-time-blocking">
                            <TeeTimeBlocking 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/shotgun-block">
                            <ShotgunBlock 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/shotgun-calendar">
                            <ShotgunCalender 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/shotgun-master">
                            <ShotgunMaster 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/shotgun-template">
                            <ShotgunTemplate 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/staggered-block">
                            <StaggeredBlock 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/staggered-master">
                            <StaggeredMaster 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/staggered-template">
                            <StaggeredTemplate 
                                title={toggleState}
                            />
                        </Route>
                        <Route path="/teetime-publish">
                               <TeeTimePublish title={toggleState}/>
                        </Route>
                        <Route path="/no-show-report">
                               <TeeTimeNoShowReport title={toggleState}/>
                        </Route>
                    {/* Tee Time End */}

                    {/* Sports */}
                        <Route path="/sports-booking">
                               <SportsBookingSheet title={toggleState}/>
                        </Route>
                        <Route path="/sport-cancel-sheet">
                               <SportsCancellationSheet title={toggleState}/>
                        </Route>
                        <Route path="/sports-blocking">
                               <SportsBlocking title={toggleState}/>
                        </Route>
                        <Route path="/sports-master">
                               <SportsMaster title={toggleState}/>
                        </Route>
                        <Route path="/facility-master">
                               <FacilityMaster title={toggleState}/>
                        </Route>
                        <Route path="/time-master">
                               <TimeMaster title={toggleState}/>
                        </Route>
                        <Route path="/sports-template">
                               <SportsTemplate title={toggleState}/>
                        </Route>
                        <Route path="/sports-publish">
                               <SportsPublish title={toggleState}/>
                        </Route>
                        <Route path="/sports-report">
                               <SportsReport title={toggleState}/>
                        </Route>
                    {/* Sports End */}

                    {/* Payment */}
                        <Route path="/payment-report">
                               <PaymentReport title={toggleState}/>
                        </Route>
                        
                    {/* Payment */}

                    {/* App Control */}
                        <Route path="/android-update" exact>
                            <AndroidUpdate
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/android-service" exact>
                            <AndroidService
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/ios-update" exact>
                            <IOSUpdate
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/ios-service" exact>
                            <IOSService
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/admin-update" exact>
                            <AdminUpdate
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/admin-service" exact>
                            <AdminService
                                title={toggleState} 
                            />
                        </Route>
                    {/* App Control End*/}

                    {/* Users */}
                        <Route path="/user" exact>
                            <Users
                                title={toggleState} 
                            />
                        </Route>
                        <Route path="/guest" exact>
                            <Guest
                                title={toggleState} 
                            />
                        </Route>
                    {/* Users End*/}

                    {/* Default */}
                        <Redirect to={'/' + menuID} />
                    
                    </Switch>
                </div>}
            </div>
            
        </div>
    );
};

export default withRouter(Admin);