import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import { getUserAll } from "../api/usersAPI";
import { getScoreCardMasterByTeeBoxID, getTeeBoxAll, getTournamentCalenderAll, getViewHoleInOneAll, postHoleInOne } from "../api/golfClubAPI";
import swal from 'sweetalert2';
import Select from 'react-select';
import * as helper from '../global/helper';
import { uploadPublicFile } from "../api/fileAPI";
import ImageCropper from "../global/imageCropper";

const HoleInOne  = (props) => {

    const [holeInOne,               setHoleInOne]               = useState({});
    const [holeInOneList,           setHoleInOneList]           = useState([]);
    const [usersList,               setUsersList]               = useState([]);
    const [teeBoxList,              setTeeBoxList]              = useState([]);
    const [tournamentCalenderList,  setTournamentCalenderList]  = useState([]);
    const [isInit,                  setIsInit]                  = useState(true);
    const [rightSideDetails,        setRightSideDetails]        = useState(false);
    const [scoredcardList,          setScorecardList]           = useState([]);
    const [scoredcardDetails,       setScorecardDetails]        = useState({});

    //<--- Cropper -->
    const [holeInOnePhotoBlob,      SetHoleInOneBlob]           = useState(null);
    const [cropperData,             setCropperData]             = useState(null);

    useEffect(() => {
        props.title("Hole in one", "hole-in-one")   
        fetchHoleInOne();
        fetchUsers();
        fetchTeeBox();
        fetchTournamentCalender();
    }, []);

    useEffect(() =>{
        if(rightSideDetails){
            Config.Core.OpenDrawer()      
            $('#txtDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date(),
            });
            $('#txtDate').on('change', function (event) {
                updateHoleInOneDetails('Date',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });  
        }else{
            setHoleInOne({});
            SetHoleInOneBlob(null);

        }
    }, [rightSideDetails])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();                      
        }
    }, [holeInOneList]);
    
    useEffect(() => {
        if(holeInOne?.TeeBoxID){
            fetchScorecardMaster(holeInOne.TeeBoxID);
        }
    },[holeInOne])

    const fetchScorecardMaster = async(id) =>{
        try {
            const response = await getScoreCardMasterByTeeBoxID(id);
            setScorecardList(response.ScoreCardMaster);

        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    const fetchUsers = async() =>{
        try {
            const users = await getUserAll()
            setUsersList(users)
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchTeeBox = async() =>{
        try {
            const teeBoxs = await getTeeBoxAll()   
            setTeeBoxList(teeBoxs);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchTournamentCalender = async() =>{
        try {
            const calender = await getTournamentCalenderAll()   
            setTournamentCalenderList(calender);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchHoleInOne = async() =>{
        try {
            const hole = await getViewHoleInOneAll()   
            isInit ? setIsInit(false) : $('#tblHoleInOne').KTDatatable().destroy(); 
            setHoleInOneList(hole); 
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    function fillDataTable() {

        const dataTable = $('#tblHoleInOne').KTDatatable({
            data: {
                saveState: false,
                source: holeInOneList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'User',
                    width:  500,
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                        ${(row.Photo && row.Photo != ''  ) ? `<img src="${row.Photo}" alt="user-Photo" height="50" width="50">` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1).toUpperCase()}</div>`}
                                    </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span> <span class="kt-user-card-v2__desc text-clip"> ${row.MembershipNo} </span>
                                    </div>
                               </div>`
                    }
                },
                {
                    field: 'Date',
                    title: 'Date',
                    template: function (row) {
                        return moment(row.Date).format('DD-MMM-YYYY');
                    }
                },
            ]
        });

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setHoleInOne(dataTable.dataSet[index]);  
                setRightSideDetails(true);
            } 
        });

    }
    
    const updateHoleInOneDetails = (field, value) =>{
        setHoleInOne((d) => {
            return { ...d, [field]: value };
        });
    }

    const addHoleInOneHandler = async(e) => {
        e.preventDefault()

        const btn = $('#btnSave');
        var form = $('#add-holeInOne-form');
        
        form.validate({
            rules: {
                ddlUser: {
                    required: true,
                },
                ddlTeeBox: {
                    required: true
                },
                txtHole: {
                    required: true
                },
                txtClub: {
                    required: true
                },
                ddlTournamentCalender: {
                    required: true
                },
                txtDate: {
                    required: true
                }                           
            },
            messages: {
                ddlUser: {
                    required: "Please select user"
                },
                ddlTeeBox: {
                    required: "Please select teebox"
                },
                txtHole: {
                    required: "Please enter hole"
                },
                txtClub: {
                    required: "Please enter club",
                },
                ddlTournamentCalender: {
                    required: "Please select tournament calender",
                },
                txtDate: {
                    required: "Please select date"
                }
            }
        });

        
        if (!form.valid()) return;
       
        helper.StartProcessing(btn);

        try {
            let holeInOneImage = '';
            //Call upload API -->
            if(holeInOnePhotoBlob){
                const res = await uploadPublicFile(holeInOnePhotoBlob, 'Content');
                holeInOneImage = res.fileName;  
            }else{
                if(holeInOne.Image) holeInOneImage = holeInOne.Image.split('/Content/')[1];
            }

            await postHoleInOne({...holeInOne, Image: holeInOneImage})
            helper.StopProcessing(btn);

            if (holeInOne.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Hole In One updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Hole In One added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            closeDrawer();
            fetchHoleInOne();

        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }
    }

    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 500, height : 250},
            dataHandler : (val) =>  SetHoleInOneBlob(val),
            header : 'Upload Image'
        })
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Hole In One..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Hole In One</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblHoleInOne" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Hole In One Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form className="kt-form" id="add-holeInOne-form">
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Player <span className="red">*</span></label>
                                                    <div className="col-3">
                                                        <Select
                                                            value={(usersList && usersList.length > 0 && holeInOne.UserID) && {label: usersList.find(m => m.ID == holeInOne.UserID).UserName, value: holeInOne.UserID}}
                                                            onChange={(event) => updateHoleInOneDetails('UserID', event.value)}
                                                            name="ddlUser"
                                                            placeholder="Select User"
                                                            id="ddlUser"
                                                            className="ddlUser"
                                                            options={usersList.map(c => ({
                                                                label: c.UserName,
                                                                value: c.ID
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">TeeBox <span className="red">*</span></label>
                                                    <div className="col-2">
                                                        <Select
                                                            value={(teeBoxList && teeBoxList.length > 0 && holeInOne.TeeBoxID) && {label: teeBoxList.find(m => m.ID == holeInOne.TeeBoxID).Name, value: holeInOne.TeeBoxID}}
                                                            onChange={(event) => updateHoleInOneDetails('TeeBoxID', event.value)}
                                                            name="ddlTeeBox"
                                                            placeholder="Select TeeBox"
                                                            id="ddlTeeBox"
                                                            className="ddlTeeBox"
                                                            options={teeBoxList.map(c => ({
                                                                label: c.Name,
                                                                value: c.ID
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">
                                                        Cover Image
                                                    </label>
                                                    <div className="col-sm-9">                                                        
                                                        {(!!holeInOnePhotoBlob || holeInOne.Image) &&(<>
                                                            <img height={150} src={holeInOnePhotoBlob ? URL.createObjectURL(holeInOnePhotoBlob) : holeInOne.Image && (holeInOne.Image) }/>
                                                        </>) }
                                                        <p className="mt-3">
                                                            <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={openCropper}>
                                                               {(!!holeInOnePhotoBlob || holeInOne.Image) ? 'Change Image' : 'Upload Image'}
                                                            </button>
                                                        </p>
                                                        <p className="">(500 W x 250 H pixels)</p>                                                       
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Hole <span className="red">*</span></label>
                                                    <div className="col-1">
                                                        <Select 
                                                            id="ddlHole" 
                                                            name="ddlHole"
                                                            className="multi-custom"
                                                            value={(scoredcardList && scoredcardList.length > 0 && holeInOne.Hole) && {label: scoredcardList.find((m) => m.HoleNoValue == holeInOne.Hole).HoleNoValue, value: holeInOne.Hole} || ''}
                                                            onChange={(event) => updateHoleInOneDetails('Hole', event.value)}
                                                            options={scoredcardList.map(m => ({
                                                                label: m.HoleNoValue,
                                                                value: m.HoleNoValue
                                                            }))}
                                                        />
                                                    </div>
                                                    <label className="col-form-label col-lg-1">Yard </label>
                                                    <div className="col-1">
                                                        <input 
                                                            id="txtYard" 
                                                            name="txtYard" 
                                                            disabled
                                                            type="number" 
                                                            className="form-control"
                                                            value={(scoredcardList && scoredcardList.length > 0 && holeInOne.Hole && scoredcardList.find((m) => m.HoleNoValue == holeInOne.Hole).Yard)|| ''}
                                                        />	
                                                    </div>
                                                    <label className="col-form-label col-lg-1">Par </label>
                                                    <div className="col-1">
                                                        <input 
                                                            id="txtPar" 
                                                            name="txtPar" 
                                                            disabled
                                                            type="number" 
                                                            className="form-control"
                                                            value={(scoredcardList && scoredcardList.length > 0 && holeInOne.Hole) && scoredcardList.find((m) => m.HoleNoValue == holeInOne.Hole).Par|| ''}
                                                        />	

                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Golf Club Used <span className="red">*</span></label>
                                                    <div className="col-xl-4 col-lg-6">
                                                        <input 
                                                            id="txtClub" 
                                                            name="txtClub" 
                                                            type="text" 
                                                            className="form-control" 
                                                            value={holeInOne.Club || ''}
                                                            onChange={(event) => updateHoleInOneDetails('Club', event.target.value)} 
                                                        />	
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Calender <span className="red">*</span></label>
                                                    <div className="col-3">
                                                        <Select
                                                            value={(tournamentCalenderList && tournamentCalenderList.length > 0 && holeInOne.TournamentCalendarID) && {label: tournamentCalenderList.find(m => m.ID == holeInOne.TournamentCalendarID).Name, value: holeInOne.TournamentCalendarID}}
                                                            onChange={(event) => updateHoleInOneDetails('TournamentCalendarID', event.value)}
                                                            name="ddlTournamentCalender"
                                                            placeholder="Select Calender"
                                                            id="ddlTournamentCalender"
                                                            className="ddlTournamentCalender"
                                                            options={tournamentCalenderList.map(c => ({
                                                                label: c.Name,
                                                                value: c.ID
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Date <span className="red">*</span></label>
                                                    <div className="col-xl-4 col-lg-6">
                                                        <div className="input-group date">
                                                            <input 
                                                                id="txtDate" 
                                                                name="txtDate" 
                                                                type="text" 
                                                                className="form-control date-input" 
                                                                readOnly={true} 
                                                                placeholder="Select date" 
                                                                value={holeInOne.Date ? moment(holeInOne.Date).format('DD-MM-YYYY') : ''} 
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="la la-calendar-check-o"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="form-group row">               
                                                    <div className = "offset-xl-3 col-xl-2 offset-lg-3 col-lg-3 col-sm-3">
                                                        <a id="btnComment" href="#" className="hidden padding-r20">
                                                            <i className="fa fa-comments bigger-110"></i>&nbsp View Comments  <span id="commentCount"></span>
                                                        </a> 
                                                    </div>
                                                    <div className = "col-xl-2 col-lg-3 col-sm-3">
                                                        <a id="btnLike" href="#" className="hidden padding-r20">
                                                            <i className="fa fa-thumbs-up bigger-110"></i>&nbsp View Likes  <span id="likeCount"></span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="hr hr-10 hr-dotted">
                                                    </div>
                                                </div> */}
                                                <div className="kt-portlet__foot">
                                                    <div className="kt-form__actions">
                                                        <div className="row">
                                                            <div className="col-lg-9 ml-lg-auto">
                                                                <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addHoleInOneHandler}>Save</button>
                                                                <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>  
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
        </>
    )

}

export default HoleInOne;