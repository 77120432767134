import { useEffect, useState } from "react";
import ic_check_on from '../../assets/images/ic_check_on.png'
import swal from 'sweetalert2';
import { getSportsSessionBookSheet } from "../api/sportsAPI";
import * as helper from '../global/helper';
import Loading from "../global/loading";

const SessionModal = (props) => {

    const {setRightSideDetails, setBookSheet, fetchBookingDetails, selectedDate, sportTemplate, setTypeOption} = props;

    const [sportsBookSheet,            setSportsBookSheet]        = useState([]);
    const [loading,                    setLoading]                = useState(false);

    useEffect(() => {
        $('#session-modal').modal({
            backdrop: 'static',
        });
        $('#session-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });
        $('#session-modal').modal('toggle');

    },[])
    
    useEffect(() => {
        if(selectedDate, sportTemplate){
            fetchSportsSessionBookSheet(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), sportTemplate.TemplateID)
        }
    },[selectedDate, sportTemplate])


    const fetchSportsSessionBookSheet = async(date, sportTemplateId) => {
        try {
            setLoading(true);
            const response = await getSportsSessionBookSheet(date, sportTemplateId);
            setSportsBookSheet(response);
            setLoading(false);

        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
            setLoading(false);

        }
    }

    const OpenDrawer = (data) => {
        setTypeOption([{isY: data.MinimumMemberAdmin == 0 ? 'N' : 'Y', value:  'Member', label: 'Member'}, {isY: data.MinimumGuestAdmin == 0 ? 'N' : 'Y', value:  'Guest', label: 'Guest'}, {isY: data.MinimumMarkerAdmin == 0 ? 'N' : 'Y', value: 'Marker', label: 'Marker'}]);
        setRightSideDetails(true);
        setBookSheet({...data, Type: 'Member', ID: data.SportBookID, UserID: '', ConcurrentBookingAdmin: sportTemplate.ConcurrentBookingAdmin, MaximumBookingAdmin: sportTemplate.MaximumBookingAdmin, MinimumMemberAdmin: sportTemplate.MinimumMemberAdmin, MaximumMemberAdmin: sportTemplate.MaximumMemberAdmin, MinimumGuestAdmin: sportTemplate.MinimumGuestAdmin, MaximumGuestAdmin: sportTemplate.MaximumGuestAdmin, MinimumMarkerAdmin: sportTemplate.MinimumMarkerAdmin, MaximumMarkerAdmin: sportTemplate.MaximumMarkerAdmin, MinimumPlayerAdmin: sportTemplate.MinimumPlayerAdmin,MaximumPlayerAdmin: sportTemplate.MaximumPlayerAdmin});  
        fetchBookingDetails(data.SportBookID);
        $('#session-modal').modal('hide');

    }

    return(
        <>
            <div id="session-modal" className="modal fade show" role="dialog" style={{paddingRight: '17px', display: 'block'}} aria-modal="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content" style={{minHeight: '590px'}}>
                        <div className="modal-header">
                            <h5 className="modal-title" style={{paddingLeft: '43%'}}> 
                                {sportTemplate?.Slot}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"></span>
                            </button>
                        </div>
                        <div className="modal-body modal-body-fit ">
                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded  margin-b0">
                                {loading ? <Loading/> : (sportsBookSheet.BookSheet && sportsBookSheet.BookSheet.length > 0 ? (
                                    <div className="kt-section__body mb-5 padding-30">
                                        <div className='row'>
                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                            </div>
                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0 scrolTable" onScroll={helper.setStickyHeader}>
                                                <table className="kt-datatable__table overflow-x-auto" style={{border: '1px solid #ebedf2' , display: 'block', bordercollapse: 'collapse'}} >
                                                    <thead className="kt-datatable__head" >
                                                        <tr className="kt-datatable__row">
                                                            <th className="kt-datatable__cell sessionTable-border" >
                                                                <span style={{width: '200px'}}>Player</span>
                                                            </th>
                                                            <th className="kt-datatable__cell sessionTable-border">
                                                                <span style={{width: '150px'}}>Time</span>
                                                            </th>
                                                            <th className="kt-datatable__cell sessionTable-border">
                                                                <span style={{width: '100px'}}>Status</span>
                                                            </th>
                                                            <th className="kt-datatable__cell sessionTable-border">
                                                                <span style={{width: '35px'}}></span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                                        {sportsBookSheet.BookSheet.map((g, i) => (
                                                            <tr key={i} data-row="0" className="kt-datatable__row">
                                                                <td title={g.UserNameAdmin} className="kt-datatable__cell sessionTable-border">
                                                                <span style={{width: '200px'}}><span className={`kt-badge kt-badge--${g.UserType == "User" ? 'success' : (g.UserType == "Guest" || g.UserType == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot`}></span>&nbsp;&nbsp;{g.UserNameAdmin}{g.IsPresent == "Y" && <img src={ic_check_on} style={{height: '20px', width: '20px', float:'right'}}/>}</span>
                                                                </td>   
                                                                <td title={g.CreatedDate} className="kt-datatable__cell sessionTable-border">
                                                                    <span style={{width: '150px'}}>{moment(g.CreatedDate).format('DD-MMM hh:mm:ss.SS A')}</span>
                                                                </td>   
                                                                <td title={g.Status} className="kt-datatable__cell sessionTable-border">
                                                                    <span style={{width: '100px'}}><div className={`badge badge-${g.Status == null ? 'success' : 'danger'}`}><span>{g.Status == null ? 'Available' : g.Status}</span></div></span>
                                                                </td>
                                                                <td data-field="" className="kt-datatable__cell sessionTable-border">
                                                                    <span style={{width: '35px'}}>
                                                                        {moment(moment(sportsBookSheet.ServerDate).format('DD-MMM-YYYY')).isSameOrBefore(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD')) && (
                                                                            <center>
                                                                                <button type="button" className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-edit" onClick={() => OpenDrawer(g)}>
                                                                                    <i className="flaticon2-edit"></i>
                                                                                </button>
                                                                            </center>
                                                                        )}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))}                                          
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ):(
                                    <span className="empty-table-message" style={{paddingTop: '15%', fontSize: '30px'}}>No Bookings Found</span>
                                ))}
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SessionModal;

