import { useState } from "react";
import { useEffect } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import Select from 'react-select';
import { getTournamentCalenderAll } from "../api/golfClubAPI";
import { getEventAll, getEventMediaByEventID, postEvent } from "../api/eventAPI";
import { uploadPublicFile } from "../api/fileAPI";
import ImageUploader from "../global/imageUploader";
import ImageCropper from "../global/imageCropper";


const Event = (props) => {


    const [eventList,                  setEventList]                  = useState([]);
    const [event,                      setEvent]                      = useState({Active: 'Y', IsRegistration: 'N'});
    const [tournamentCalenderList,     setTournamentCalenderList]     = useState([]);
    const [rightSideDetails,           setRightSideDetails]           = useState(false);
    const [isInit,                     setIsInit]                     = useState(true);

        //<--- Cropper -->
    const [eventPhotoBlob,             SetEventPhotoBlob]             = useState(null);
    const [thumbnailPhotoBlob,         SetThumbnailPhotoBlob]         = useState(null);
    const [cropperData,                setCropperData]                = useState(null);
    
    
    //<--- Multi Image Uploader -->
    const [showUploader,               setShowUploader]               = useState(false);
    const [multiImages,                setMultiImages]                = useState([]);

    useEffect(() => {
        props.title('Event', 'event')
        fetchEvent()
        fetchTournamentCalender()
    },[])

    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer()
            // window.initCkeditor("#editorContent");
            // if(event && event.Content) window.setEditorValue("#editorContent", event.Content);
           
            $('#txtFromDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtFromDate').on('change', function (event) {
                updateEventDetails('FromDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });
            $('#txtToDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtToDate').on('change', function (event) {
                updateEventDetails('ToDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });
            $('#txtCloseDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtCloseDate').on('change', function (event) {
                updateEventDetails('CloseDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            }); 
           
        }else{
            // window.removeCkeditor("editorContent");
            setEvent({Active: 'Y', IsRegistration: 'N'});
            SetEventPhotoBlob(null);
            SetThumbnailPhotoBlob(null);
            setMultiImages([])
        }
    },[rightSideDetails])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();        
        }
    }, [eventList]);

    useEffect(() => {       
        if (event) {
            $('#txtCloseDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtCloseDate').on('change', function (event) {
                updateEventDetails('CloseDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });    
        }
    }, [event]);

    const fetchEvent = async() =>{
        try {
            const events = await getEventAll()   
            isInit ? setIsInit(false) : $('#tblEvent').KTDatatable().destroy(); 
            setEventList(events);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchEventMedia = async(ID) =>{
        try {
            const eventMedia = await getEventMediaByEventID(ID)   
            setMultiImages(eventMedia);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchTournamentCalender = async() =>{
        try {
            const calender = await getTournamentCalenderAll()   
            setTournamentCalenderList(calender);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    function fillDataTable() {

        const dataTable = $('#tblEvent').KTDatatable({
            data: {
                saveState: false,
                source: eventList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Title',
                    title: 'Event',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Thumbnail && row.Thumbnail != '') ? `<img src="${row.Thumbnail}" alt="user-profile" height="50" width="50">` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Title.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Title} </span> 
                                    <span class="kt-user-card-v2__desc text-clip"> ${moment(moment(row.FromDate).format('YYYY-MM-DD')).isSame(moment(row.ToDate).format('YYYY-MM-DD')) ? moment(row.FromDate).format('DD MMM YYYY') : moment(row.FromDate).format('DD MMM YYYY') + ' - ' + moment(row.ToDate).format('DD MMM YYYY') } </span>
                                </div>
                            </div>`
                    }
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        if (row.Active == 'Y') {
                            if (row.FromDate && row.ToDate) {
                                if (moment(row.ServerDate).isBetween(moment(moment(row.FromDate).format('YYYY-MM-DD')), moment(moment(row.ToDate).format('YYYY-MM-DD')), '[]'))
                                    return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                                else
                                    return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                            } else
                                return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 50,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblEvent .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setEvent(dataTable.dataSet[index]);  
                fetchEventMedia(dataTable.dataSet[index].ID);
                setRightSideDetails(true); 
            }    
        });


    }

    const dataHandler = (f) => {
        if(f.length > 0){
            const newArr = f.map( s => ({imgSrc:s.img, Caption:'', File: s.File}));
            setMultiImages( d => ([...d, ...newArr]));
        }
    }

    const onCaptionChange = (d,i) => {              
        setMultiImages((o) => {            
            o[i].Caption = d;        
            return [...o];            
        }) 
    }
    
    const onMediaRemove = (i) => {        
        setMultiImages((m)=>{
            m.splice(i,1);
            return [...m];
        });
    }

    const updateEventDetails = (field, value) => {   
        setEvent((d) => {
            return { ...d, [field]: value };
        });
    };

    const addEventHandler = async() => {

        const btn = $('#btnSave');
        var form = $('#add-event-form');
        // const Content = window.getEditorValue("#editorContent");

        form.validate({
            rules: {
                txtEventTitle: {
                    required: true
                },          
                ddlCalender: {
                    required: true
                },          
                txtFromDate: {
                    required: true
                },          
                txtToDate: {
                    required: true
                }          
            },
            messages: {
                txtEventTitle: {
                    required: "Please enter event title"
                },
                ddlCalender: {
                    required: "Please select calender"
                },
                txtFromDate: {
                    required: "Please select fromDate"
                },
                txtToDate: {
                    required: "Please select toDate"
                }
            }
        });

        if (!form.valid()) return;
        if((moment(event.FromDate).isAfter(event.ToDate)) || (event.CloseDate && (!moment(event.CloseDate).isBetween(moment(event.FromDate).subtract(1, "days"), moment(event.ToDate).add(1, "days"))))){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Date Conflict!',
                buttonsStyling        : false,  
                confirmButtonClass    : 'btn btn-brand',   
            });
            helper.StopProcessing(btn);
            return  
        }
        helper.StartProcessing(btn);

        try {
            let eventCoverImage = '';
            //Call upload API -->
            if(eventPhotoBlob){
                const res = await uploadPublicFile(eventPhotoBlob,'Content');
                eventCoverImage = res.fileName;            
            }else{
                if(event.CoverImage) eventCoverImage = event.CoverImage.split('/Content/')[1];
            }

            let eventThumbnail = '';
            //Call upload API -->
            if(thumbnailPhotoBlob){
                const res = await uploadPublicFile(thumbnailPhotoBlob,'Content');
                eventThumbnail = res.fileName;            
            }else{
                if(event.Thumbnail) eventThumbnail = event.Thumbnail.split('/Content/')[1];
            }

            let mediaList = [];
            for (const  [index, m]  of multiImages.entries()) {
                let eventImage = '';
                if(m.File && m.File !== '' && !m.ID){
                    const res = await uploadPublicFile(m.File, 'Content')
                    eventImage = res.fileName;
                }

                mediaList.push({
                    ID      :   m.ID || '',
                    Caption :   m.Caption,
                    Image   :   (m.Image && m.Image.split("/").pop()) || eventImage,
                    Index   :   index
                })
            }

            const eventDetails = {
                ID:                     event.ID || '',
                Title:                  event.Title,
                Content:                event.Content || '',
                // Content:                Content || '',
                FromDate:               event.FromDate,
                ToDate:                 event.ToDate,
                CoverImage:             eventCoverImage || '',
                File:                   event.File || '',
                Active:                 event.Active,
                IsRegistration:         event.IsRegistration || '',
                Thumbnail:              eventThumbnail || '',
                CloseDate:              event.CloseDate,
                TournamentCalenderID:   event.TournamentCalenderID,
                RegistrationLink:       event.RegistrationLink || ''
            }

            await postEvent(eventDetails, mediaList);
            helper.StopProcessing(btn);
            closeDrawer();
            fetchEvent();
            if (event.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Event updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Event added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }
    
    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 500, height : 250},
            dataHandler : (val) =>  SetEventPhotoBlob(val),
            header : 'Upload Cover Image'
        })
    }

    const openThumbnailCropper = () =>{
        setCropperData({
            imageRatio  : { width : 128, height : 128},
            dataHandler : (val) =>  SetThumbnailPhotoBlob(val),
            header : 'Upload Thumbnail'
        })
    }

    const closeDrawer = () =>{
        // window.removeCkeditor("editorContent");
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }
    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Event..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Event</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblEvent" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Event Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form className="kt-form" id="add-event-form">
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Event Title <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtEventTitle" 
                                                            name="txtEventTitle" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={event.Title || ''}
                                                            onChange={(event) => updateEventDetails('Title', event.target.value)} 
                                                        />						
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Event Calender <span className="red">*</span></label>
                                                    <div className="col-xl-3 col-lg-4">
                                                        <Select
                                                            value={(tournamentCalenderList && tournamentCalenderList.length > 0 && event.TournamentCalenderID) && {label: tournamentCalenderList.find(c => c.ID == event.TournamentCalenderID).Name, value: event.TournamentCalenderID}}
                                                            onChange={(event) => updateEventDetails('TournamentCalenderID', event.value)}
                                                            name="ddlCalender"
                                                            placeholder="Select Calender"
                                                            id="ddlCalender"
                                                            className="ddlCalender"
                                                            options={tournamentCalenderList.map(c => ({
                                                                label: c.Name,
                                                                value: c.ID
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Event Content </label>
                                                    {/* Content- CkEditor */}
                                                    {/* <div className="row margin-t20">
                                                        <div className="col-12">
                                                            <textarea name="editorContent" id="editorContent" />
                                                        </div>
                                                    </div> */}
                                                    <div className="col-xl-6 col-lg-8">
                                                        <textarea 
                                                            id="txtContent" 
                                                            name="txtContent" 
                                                            className="form-control" 
                                                            rows="3"
                                                            value={event.Content || ''}
                                                            onChange={(event) => updateEventDetails('Content', event.target.value)}
                                                        ></textarea>
                                                    </div>                                      
                                                </div>                                      
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">From Date <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <div className="input-group date">
                                                            <input
                                                                id="txtFromDate"
                                                                name="txtFromDate"
                                                                type="text"
                                                                className="form-control date-input" 
                                                                readOnly={true}
                                                                placeholder="Select date"
                                                                value={event.FromDate ? moment(event.FromDate).format('DD-MM-YYYY') : ''} 
                                                                />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="la la-calendar-check-o"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">To Date <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <div className="input-group date">
                                                            <input
                                                                id="txtToDate"
                                                                name="txtToDate"
                                                                type="text"
                                                                className="form-control date-input" 
                                                                readOnly={true}
                                                                placeholder="Select to date"
                                                                value={event.ToDate ? moment(event.ToDate).format('DD-MM-YYYY') : ''} 
                                                                />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="la la-calendar-check-o"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> Cover Image </label>
                                                    <div className="col-sm-9">                                                        
                                                        {(!!eventPhotoBlob || event.CoverImage) &&(<>
                                                            <img height={150} src={eventPhotoBlob ? URL.createObjectURL(eventPhotoBlob) : event.CoverImage && (event.CoverImage) }/>
                                                        </>) }
                                                        <p className="mt-3">
                                                            <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={openCropper}>
                                                               {(!!eventPhotoBlob || event.CoverImage) ? 'Change Image' : 'Upload Image'}
                                                            </button>
                                                        </p>
                                                        <p className="">(500 W x 250 H pixels)</p>                                                       
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> Thumbnail </label>
                                                    <div className="col-sm-9">                                                        
                                                        {(!!thumbnailPhotoBlob || event.Thumbnail) &&(<>
                                                            <img height={150} src={thumbnailPhotoBlob ? URL.createObjectURL(thumbnailPhotoBlob) : event.Thumbnail && (event.Thumbnail) }/>
                                                        </>) }
                                                        <p className="mt-3">
                                                            <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={openThumbnailCropper}>
                                                               {(!!thumbnailPhotoBlob || event.Thumbnail) ? 'Change Image' : 'Upload Image'}
                                                            </button>
                                                        </p>
                                                        <p className="">(128  W x 128  H pixels)</p>                                                       
                                                    </div>
                                                </div>                                        
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Registration</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <span className="kt-switch kt-switch--icon">
                                                            <label>
                                                                <input 
                                                                    id="chkRegistration" 
                                                                    name="chkRegistration" 
                                                                    type="checkbox" 
                                                                    defaultChecked={event.IsRegistration == 'N' ? '' : 'checked'}
                                                                    onChange={(event) => updateEventDetails('IsRegistration', event.target.checked ? 'Y' : 'N')} 
                                                                />
                                                                <span></span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div>
                                                {event.IsRegistration == 'Y' && (
                                                    <>
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3">Registration Close Date</label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <div className="input-group date">
                                                                    <input
                                                                        id="txtCloseDate"
                                                                        name="txtCloseDate"
                                                                        type="text"
                                                                        className="form-control date-input" 
                                                                        readOnly={true}
                                                                        placeholder="Select close date"
                                                                        value={event.CloseDate ? moment(event.CloseDate).format('DD-MM-YYYY') : ''} 
                                                                        />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text">
                                                                            <i className="la la-calendar-check-o"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3">Registration Link </label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <input 
                                                                    id="txtRegistrationLink" 
                                                                    name="txtRegistrationLink" 
                                                                    type="text" 
                                                                    className="form-control"
                                                                    value={event.RegistrationLink || ''}
                                                                    onChange={(event) => updateEventDetails('RegistrationLink', event.target.value)}  
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )} 
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Active</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <span className="kt-switch kt-switch--icon">
                                                            <label>
                                                                <input 
                                                                    id="chkActive" 
                                                                    name="chkActive" 
                                                                    type="checkbox" 
                                                                    defaultChecked={event.Active == 'N' ? '' : 'checked'}
                                                                    onChange={(event) => updateEventDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                                />
                                                                <span></span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div> 
                                                {/* FOR Multi Image Uploader */}

                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> Images </label>
                                                    <div className="col-sm-9">                                                                                                                
                                                        <p className="">
                                                            <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={() => setShowUploader(true)}>
                                                                <i className="flaticon-upload-1"></i> Upload Images
                                                            </button>
                                                        </p>
                                                                                                            
                                                    </div>
                                                </div>
                                                {multiImages.length > 0 && (
                                                <div className="row justify-content-center">
                                                    <div className="col-xl-6">
                                                        <table className="table table-striped table-bordered table-hover">
                                                            <thead>
                                                                <tr><th>Index</th><th>Image</th><th>Caption</th><th>Action</th></tr>
                                                            </thead>
                                                            <tbody>
                                                                {multiImages.map((t,i)=>{
                                                                    return(
                                                                        <tr key={i}>
                                                                            <td className="text-center">{i + 1}</td>
                                                                            <td>
                                                                                {t.Image && t.Image != '' ?
                                                                                <img src={t.Image} alt="user-profile" width="100" height="100"/> :
                                                                                <img className="media-image" src={t.imgSrc} width="100" height="100"/>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <textarea className="form-control caption" cols="20" rows="3" value={t.Caption} onChange={({target}) => onCaptionChange(target.value, i)}/>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <button  onClick={() => onMediaRemove(i)} className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10 btn-user-delete delete-dynamic-row" type="button">
                                                                                    <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>                
                                            )}
                                            {/* Multi Image Uploader ENDS */}
                                            </div>
                                        </form>
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-lg-9 ml-lg-auto">
                                                    <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addEventHandler}>Save</button>
                                                    <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>

                    </div>
                </div>
            </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
            {showUploader && (
                <ImageUploader
                    show           = {showUploader} 
                    dataHandler    = {(d) => dataHandler(d)}                
                    onDismissModal = {() => setShowUploader(false) }
                />
            )}
        </>
    )
}

export default Event;