import { useEffect, useState } from "react";
import { updateMemberGroup } from "../api/usersAPI";
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import Select from 'react-select';

const EditUser = (props) =>{

    const [userDetails,                 setUserDetails]              = useState({});
    const [membershipTypeOption,        setMembershipTypeOption]     = useState([{ value:  'Golf Membership', label: 'Golf Membership'}, { value:  'Club Membership', label: 'Club Membership'}]);

    useEffect(() => {
        $('#edit-user-modal').modal({
            backdrop: 'static',
        });
        $('#edit-user-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);   
        });
        $('#txtSubscriptionExpiresOn').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });
        $('#txtSubscriptionExpiresOn').on('change', function (event) {
            updateUsersDetails('SubscriptionExpiresOn',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        });   
        $('#txtMembershipExpiresOn').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });
        $('#txtMembershipExpiresOn').on('change', function (event) {
            updateUsersDetails('MembershipExpiresOn',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        }); 
    })

    useEffect(() => {
        if (props.userDetails) {
            setUserDetails(props.userDetails)
        }
    }, [props.userDetails])

    const addMembersHandler = async(e) => {
        e.preventDefault()

        const btn = $('#btnSave');
        var form = $('#add-userDetails-form');
        
        form.validate({
            rules: {
                txtMembershipNo: {
                    required: true,
                },
                txtSubscriptionExpiresOn: {
                    required: true,
                },
                txtMembershipExpiresOn: {
                    required: true
                }                        
            },
            messages: {
                txtMembershipNo: {
                    required: "Please enter membership no"
                },
                txtSubscriptionExpiresOn: {
                    required: "Please select subscription expires On"
                },
                txtMembershipExpiresOn: {
                    required: "Please select membership expires On"
                }
            }
        });

        if (!form.valid()) return;

        helper.StartProcessing(btn);

        try {
           
            const user = {
                ID:                      userDetails.GroupID,
                MembershipType:          userDetails.MembershipType,
                MembershipNo:            userDetails.MembershipNo,
                SubscriptionExpiresOn:   userDetails.SubscriptionExpiresOn,
                MembershipExpiresOn:     userDetails.MembershipExpiresOn,
                PanCardNo:               userDetails.PanCardNo || '',
                AadharCardNo:            userDetails.AadharCardNo || '',
                GstNo:                   userDetails.GstNo || '',
                Address:                 userDetails.Address || '',
                City:                    userDetails.City || '',
                State:                   userDetails.State || '',
                Country:                 userDetails.Country || '',
                Pincode:                 userDetails.Pincode || '',
                Phone:                   userDetails.Phone || '',
                Nominee:                 userDetails.Nominee,
                Remark:                  userDetails.Remark || '',
                Active:                  userDetails.Active     
            };


            await updateMemberGroup(user)
            helper.StopProcessing(btn);

            props.refetchUsers()
            swal.fire({
                icon: 'success',
                titleText: 'User updated successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
            $('#edit-user-modal').modal('hide');
            

        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }


        form.clearForm();
        form.validate().resetForm();

    }
    
    const updateUsersDetails = (field, value) => { 
        setUserDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const closeDrawer = () =>{
        setUserDetails({});
    }

 
    return (
        <>
        <div className="modal fade"id="edit-user-modal"tabIndex="-1"role="dialog"aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Update User</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeDrawer}
                            >
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="kt-form" id="add-userDetails-form">
                                <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                    <div className="row">
                                        <div className="col-6 form-group" >
                                            <label>Membership Type</label>
                                            <Select 
                                                id="ddlMembershipType" 
                                                name="ddlMembershipType"
                                                className="multi-custom"
                                                value={userDetails && membershipTypeOption.find((m) => m.value == userDetails.MembershipType) || ''}
                                                onChange={(event) => updateUsersDetails('MembershipType', event.value)}
                                                options={membershipTypeOption}
                                            />
                                        </div>
                                        <div className="col-6 form-group" >
                                            <label>Membership No</label>
                                            <input 
                                                style={{ fontWeight: userDetails.MembershipNo ? 'bold' : '' }}
                                                id="txtMembershipNo" 
                                                name="txtMembershipNo " 
                                                type="text" 
                                                className="form-control"
                                                value={userDetails.MembershipNo  || ''}
                                                onChange={(event) => updateUsersDetails('MembershipNo', event.target.value)} 
                                            />
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-6 form-group">
                                            <label>Subscription Expires On</label>
                                            <div className="input-group date">
                                                <input 
                                                    id="txtSubscriptionExpiresOn" 
                                                    name="txtSubscriptionExpiresOn" 
                                                    type="text" 
                                                    className="form-control date-input" 
                                                    readOnly={true} 
                                                    value={userDetails.SubscriptionExpiresOn ? moment(userDetails.SubscriptionExpiresOn).format('DD-MM-YYYY') : ''} 
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="la la-calendar-check-o"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 form-group">
                                            <label>Membership Expires On</label>
                                            <div className="input-group date">
                                                <input 
                                                    id="txtMembershipExpiresOn" 
                                                    name="txtMembershipExpiresOn" 
                                                    type="text" 
                                                    className="form-control date-input" 
                                                    readOnly={true} 
                                                    value={userDetails.MembershipExpiresOn ? moment(userDetails.MembershipExpiresOn).format('DD-MM-YYYY') : ''} 
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i className="la la-calendar-check-o"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 form-group ">
                                            <label>Aadhaar Card No</label>
                                            <input 
                                                id="txtAadharCardNo" 
                                                name="txtAadharCardNo" 
                                                type="text" 
                                                className="form-control"
                                                value={userDetails.AadharCardNo || ''}
                                                onChange={(event) => updateUsersDetails('AadharCardNo', event.target.value)} 
                                            />
                                        </div>
                                        <div className="col-4 form-group ">
                                            <label>Pan Card No</label>
                                            <input 
                                                id="txtPanCardNo" 
                                                name="txtPanCardNo" 
                                                type="text" 
                                                className="form-control"
                                                value={userDetails.PanCardNo || ''}
                                                onChange={(event) => updateUsersDetails('PanCardNo', event.target.value)} 
                                            />
                                        </div>
                                        <div className="col-4 form-group ">
                                            <label>GST No</label>
                                            <input 
                                                id="txtGstNo" 
                                                name="txtGstNo" 
                                                type="text" 
                                                className="form-control"
                                                value={userDetails.GstNo || ''}
                                                onChange={(event) => updateUsersDetails('GstNo', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 form-group ">
                                            <label>Address</label>
                                            <textarea 
                                                id="txtAddress" 
                                                name="txtAddress" 
                                                className="form-control" 
                                                rows="3"
                                                value={userDetails.Address || ''}
                                                onChange={(event) => updateUsersDetails('Address', event.target.value)}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 form-group ">
                                            <label>Country</label>
                                            <input 
                                                id="txtCountry"
                                                name="txtMembershipNo " 
                                                type="text" 
                                                className="form-control"
                                                value={userDetails.Country  || ''}
                                                onChange={(event) => updateUsersDetails('Country', event.target.value)} 
                                            />
                                        </div>
                                        <div className="col-4 form-group ">
                                            <label>State</label>
                                            <input 
                                                id="txtState"
                                                name="txtState " 
                                                type="text" 
                                                className="form-control"
                                                value={userDetails.State  || ''}
                                                onChange={(event) => updateUsersDetails('State', event.target.value)} 
                                            />
                                        </div>
                                        <div className="col-4 form-group ">
                                            <label>City</label>
                                            <input 
                                                id="txtCity"
                                                name="txtCity " 
                                                type="text" 
                                                className="form-control"
                                                value={userDetails.City  || ''}
                                                onChange={(event) => updateUsersDetails('City', event.target.value)} 
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 form-group ">
                                            <label>Pincode</label>
                                            <input 
                                                id="txtPincode" 
                                                name="txtPincode" 
                                                type="text" 
                                                className="form-control"
                                                value={userDetails.Pincode || ''}
                                                onChange={(event) => updateUsersDetails('Pincode', event.target.value)} 
                                            />
                                        </div>
                                        <div className="col-4 form-group ">
                                            <label>Phone</label>
                                            <input 
                                                id="txtPhone" 
                                                name="txtPhone" 
                                                type="text" 
                                                className="form-control"
                                                value={userDetails.Phone || ''}
                                                onChange={(event) => updateUsersDetails('Phone', event.target.value)} 
                                            />
                                        </div>
                                        <div className="col-4 form-group ">
                                            <label>Nominee</label>
                                            <input 
                                                id="txtNominee" 
                                                name="txtNominee" 
                                                type="text" 
                                                className="form-control"
                                                value={userDetails.Nominee || ''}
                                                onChange={(event) => updateUsersDetails('Nominee', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 form-group ">
                                            <label>Remark</label>
                                            <textarea 
                                                id="txtRemark" 
                                                name="txtRemark" 
                                                className="form-control" 
                                                rows="3"
                                                value={userDetails.Remark || ''}
                                                onChange={(event) => updateUsersDetails('Remark', event.target.value)}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-6">Active</label>
                                        <div className="col-xl-6 col-lg-6">
                                            <span className="kt-switch kt-switch--icon">
                                                <label>
                                                    <input 
                                                        id="chkActive" 
                                                        name="chkActive" 
                                                        type="checkbox"
                                                        defaultChecked={props.userDetails.Active == 'N' ? '' : 'checked'}
                                                        onChange={(event) => updateUsersDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                    />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                    </div> 
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                // onClick={closeDrawer}
                            >
                                Close
                            </button>
                            <button
                                id="btnSave"
                                onClick={addMembersHandler}
                                type="button"
                                className={`btn btn-brand margin-0 `}
                            >                  
                                Update User
                            </button>
                        </div>
                    </div>
                </div>
        </div>
        </>
    )
}

export default EditUser