import { useEffect, useState } from "react";
import { getNoticeBoardAll, getNoticeBoardMediaByNoticeBoardID, postNoticeBoard } from "../api/noticeBoardAPI";
import RightSideDetails from "../global/rightSideDetails";
import ImageUploader from "../global/imageUploader";
import ImageCropper from "../global/imageCropper";
import { uploadPublicFile } from "../api/fileAPI";
import * as helper from '../global/helper';
import swal from 'sweetalert2';

const NoticeBoard = (props) =>{

    const [noticeBoardList,            setNoticeBoardList]            = useState([]);
    const [noticeBoard,                setNoticeBoard]                = useState({Active: 'Y'});
    const [isInit,                     setIsInit]                     = useState(true);
    const [rightSideDetails,           setRightSideDetails]           = useState(false);

    //<--- Cropper -->
    const [noticeBoardPhotoBlob,       SetNoticeBoardPhotoBlob]       = useState(null);
    const [cropperData,                setCropperData]                = useState(null);

    //<--- Multi Image Uploader -->
    const [showUploader,               setShowUploader]               = useState(false);
    const [multiImages,                setMultiImages]                = useState([]);

    useEffect(() => {
        props.title("Notice Board", "notice-board")
        fetchNoticeBoard();
    }, []);

    useEffect(() => {  
        if (rightSideDetails) {
            Config.Core.OpenDrawer()
            // window.initCkeditor("#editorContent");
            // if(noticeBoard && noticeBoard.Content) window.setEditorValue("#editorContent", noticeBoard.Content);
            $('#txtNoticeDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtNoticeDate').on('change', function (event) {
                updateNoticeBoardDetails('NoticeDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });
            $('#txtEndDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtEndDate').on('change', function (event) {
                updateNoticeBoardDetails('EndDate',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });
        }else{
            // window.removeCkeditor("editorContent");
            setNoticeBoard({Active: 'Y'});
            SetNoticeBoardPhotoBlob(null);
            setMultiImages([])
        }
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();        
        }
    }, [noticeBoardList]);

    const dataHandler = (f) => {
        if(f.length > 0){
            const newArr = f.map( s => ({imgSrc:s.img, Caption:'', File: s.File}));
            setMultiImages( d => ([...d, ...newArr]));
        }
    }

    const onCaptionChange = (d,i) => {              
        setMultiImages((o) => {            
            o[i].Caption = d;        
            return [...o];            
        }) 
    }
    
    const onMediaRemove = (i) => {        
        setMultiImages((m)=>{
            m.splice(i,1);
            return [...m];
        });
    }

    const fetchNoticeBoard = async() =>{
        try {
            const noticeBoards = await getNoticeBoardAll()   
            isInit ? setIsInit(false) : $('#tblNoticeBoard').KTDatatable().destroy(); 
            setNoticeBoardList(noticeBoards);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchNoticeBoardMedia = async(ID) =>{
        try {
            const noticeBoardMedia = await getNoticeBoardMediaByNoticeBoardID(ID)   
            setMultiImages(noticeBoardMedia);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    function fillDataTable() {

        const dataTable = $('#tblNoticeBoard').KTDatatable({
            data: {
                saveState: false,
                source: noticeBoardList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Title',
                    title: 'Notice',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.CoverImage && row.CoverImage != '') ? `<img src="${row.CoverImage}" alt="user-profile" height="50" width="50" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Title.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Title} </span> 
                                    <span class="kt-user-card-v2__desc text-clip"> ${moment(moment(row.NoticeDate).format('YYYY-MM-DD')).isSame(moment(row.EndDate).format('YYYY-MM-DD')) ? moment(row.NoticeDate).format('DD MMM YYYY') : moment(row.NoticeDate).format('DD MMM YYYY') + ' - ' + moment(row.EndDate).format('DD MMM YYYY') } </span>
                                </div>
                            </div>`
                    }
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        if (row.Active == 'Y') {
                            if (row.NoticeDate && row.EndDate) {
                                if (moment(row.ServerDate).isBetween(moment(moment(row.NoticeDate).format('YYYY-MM-DD')), moment(moment(row.EndDate).format('YYYY-MM-DD')), '[]'))
                                    return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                                else
                                    return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                            } else
                                return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 50,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblNoticeBoard .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setNoticeBoard(dataTable.dataSet[index]);  
                fetchNoticeBoardMedia(dataTable.dataSet[index].ID);
                setRightSideDetails(true); 
            }    
        });


    }
    
    const updateNoticeBoardDetails = (field, value) => {   
        setNoticeBoard((d) => {
            return { ...d, [field]: value };
        });
    };

    const addNoticeBoardHandler = async() => {

        const btn = $('#btnSave');
        var form = $('#add-noticeBoard-form');
        // const Content = window.getEditorValue("#editorContent");
        form.validate({
            rules: {
                txtTitle: {
                    required: true
                },          
                txtNoticeDate: {
                    required: true
                },          
                txtEndDate: {
                    required: true
                }          
            },
            messages: {
                txtTitle: {
                    required: "Please enter title"
                },
                txtNoticeDate: {
                    required: "Please select noticeDate"
                },
                txtEndDate: {
                    required: "Please select endDate"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            let noticeCoverImage = '';
            //Call upload API -->
            if(noticeBoardPhotoBlob){
                const res = await uploadPublicFile(noticeBoardPhotoBlob,'Content');
                noticeCoverImage = res.fileName;            
            }else{
                if(noticeBoard.CoverImage) noticeCoverImage = noticeBoard.CoverImage.split('/Content/')[1];
            }

            let mediaList = [];
            for (const  [index, m]  of multiImages.entries()) {
                let noticeBoardImage = '';
                if(m.File && m.File !== '' && !m.ID){
                    const res = await uploadPublicFile(m.File, 'Content')
                    noticeBoardImage = res.fileName;
                }

                mediaList.push({
                    ID      :   m.ID || '',
                    Caption :   m.Caption,
                    Image   :   (m.Image && m.Image.split("/").pop()) || noticeBoardImage,
                    Index   :   index
                })
                
            }
            await postNoticeBoard({...noticeBoard, CoverImage: noticeCoverImage}, mediaList);
            // await postNoticeBoard({...noticeBoard, CoverImage: noticeCoverImage, Content: Content}, mediaList);
            helper.StopProcessing(btn);
            closeDrawer();
            fetchNoticeBoard();
            if (noticeBoard.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Notice Board updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Notice Board added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }
    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        // window.removeCkeditor("editorContent");
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 500, height : 250},
            dataHandler : (val) =>  SetNoticeBoardPhotoBlob(val),
            header : 'Upload Cover Image'
        })
    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Notice Board..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Notice</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblNoticeBoard" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Notice Board Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form className="kt-form" id="add-noticeBoard-form">
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Notice Board Title <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtTitle" 
                                                            name="txtTitle" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={noticeBoard.Title || ''}
                                                            onChange={(event) => updateNoticeBoardDetails('Title', event.target.value)} 
                                                        />						
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Notice Board Content </label>
                                                    {/* Content- CkEditor */}
                                                    {/* <div className="row margin-t20">
                                                        <div className="col-12">
                                                            <textarea name="editorContent" id="editorContent" />
                                                        </div>
                                                    </div> */}
                                                   <div className="col-xl-6 col-lg-8">
                                                        <textarea 
                                                            id="txtContent" 
                                                            name="txtContent" 
                                                            className="form-control" 
                                                            rows="3"
                                                            value={noticeBoard.Content || ''}
                                                            onChange={(event) => updateNoticeBoardDetails('Content', event.target.value)}
                                                        ></textarea>
                                                    </div>  
                                                </div>                                      
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Notice Date <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <div className="input-group date">
                                                            <input
                                                                id="txtNoticeDate"
                                                                name="txtNoticeDate"
                                                                type="text"
                                                                className="form-control date-input" 
                                                                readOnly={true}
                                                                placeholder="Select date"
                                                                value={noticeBoard.NoticeDate ? moment(noticeBoard.NoticeDate).format('DD-MM-YYYY') : ''} 
                                                                />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="la la-calendar-check-o"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> Cover Image </label>
                                                    <div className="col-sm-9">                                                        
                                                        {(!!noticeBoardPhotoBlob || noticeBoard.CoverImage) &&(<>
                                                            <img height={150} src={noticeBoardPhotoBlob ? URL.createObjectURL(noticeBoardPhotoBlob) : noticeBoard.CoverImage && (noticeBoard.CoverImage) }/>
                                                        </>) }
                                                        <p className="mt-3">
                                                            <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={openCropper}>
                                                               {(!!noticeBoardPhotoBlob || noticeBoard.CoverImage) ? 'Change Image' : 'Upload Image'}
                                                            </button>
                                                        </p>
                                                        <p className="">(500 W x 250 H pixels)</p>                                                       
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">End Date <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <div className="input-group date">
                                                            <input 
                                                                id="txtEndDate" 
                                                                name="txtEndDate" 
                                                                type="text" 
                                                                className="form-control" 
                                                                readOnly={true}
                                                                placeholder="Select end date"
                                                                value={noticeBoard.EndDate ? moment(noticeBoard.EndDate).format('DD-MM-YYYY') : ''} 
                                                             />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="la la-calendar-check-o"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                         
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Active</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <span className="kt-switch kt-switch--icon">
                                                            <label>
                                                                <input 
                                                                    id="chkActive" 
                                                                    name="chkActive" 
                                                                    type="checkbox" 
                                                                    defaultChecked={noticeBoard.Active == 'N' ? '' : 'checked'}
                                                                    onChange={(event) => updateNoticeBoardDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                                />
                                                                <span></span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div> 
                                                {/* FOR Multi Image Uploader */}

                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> Images </label>
                                                    <div className="col-sm-9">                                                                                                                
                                                        <p className="">
                                                            <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={() => setShowUploader(true)}>
                                                                <i className="flaticon-upload-1"></i> Upload Images
                                                            </button>
                                                        </p>
                                                                                                            
                                                    </div>
                                                </div>
                                                {multiImages.length > 0 && (
                                                <div className="row justify-content-center">
                                                    <div className="col-xl-6">
                                                        <table className="table table-striped table-bordered table-hover">
                                                            <thead>
                                                                <tr><th>Index</th><th>Image</th><th>Caption</th><th>Action</th></tr>
                                                            </thead>
                                                            <tbody>
                                                                {multiImages.map((t,i)=>{
                                                                    return(
                                                                        <tr key={i}>
                                                                            <td className="text-center">{i + 1}</td>
                                                                            <td>
                                                                                {t.Image && t.Image != '' ?
                                                                                <img src={t.Image} alt="user-profile" width="100" height="100"/> :
                                                                                <img className="media-image" src={t.imgSrc} width="100" height="100"/>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <textarea className="form-control caption" cols="20" rows="3" value={t.Caption} onChange={({target}) => onCaptionChange(target.value, i)}/>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <button  onClick={() => onMediaRemove(i)} className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10 btn-user-delete delete-dynamic-row" type="button">
                                                                                    <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>                
                                            )}
                                            {/* Multi Image Uploader ENDS */}
                                            </div>
                                        </form>
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-lg-9 ml-lg-auto">
                                                    <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addNoticeBoardHandler}>Save</button>
                                                    <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
            {showUploader && (
                <ImageUploader
                    show           = {showUploader} 
                    dataHandler    = {(d) => dataHandler(d)}                
                    onDismissModal = {() => setShowUploader(false) }
                />
            )}
        </>
    )
}

export default NoticeBoard;