import swal from 'sweetalert2';
import Select from 'react-select';
import { useEffect, useState } from "react";
import * as helper from '../global/helper';
import { postMarker } from '../api/sportsAPI';


const AddMarker = (props) => {
    const [marker,                   setMarker]                     = useState({});

    useEffect(() => {
        $('#modalMarker').modal({
            backdrop: 'static',
        });
        $('#modalMarker').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });
      
    }, [])

    useEffect(() => {
        if(props.mobileCodeList){
            setMarker({CountryCode : props.mobileCodeList[0].Code})
        }
    },[props.mobileCodeList])

    const updateGuestDetails = (field, value) => { 
        setMarker((d) => {
            return { ...d, [field]: value };
        });
    };

    const addGuestHandler = async(e) => {
        e.preventDefault()
        const btn = $('#btnSaveMarker');
        var form = $('#add-marker-form');
        
        form.validate({
            rules: {
                txtMarkerName: {
                    required: true,
                },
                txtMarkerMobile: {
                    number: true,
                }                     
            },
            messages: {
                txtMarkerName: {
                    required: "Please enter name"
                }
            }
        });
        
        if (!form.valid()) return;

       try {
        const guestDetails = {
            ID:                    marker.ID || 0,
            Name:                  marker.Name,
            Email:                 marker.Email || '',
            Photo:                 marker.Photo || '',
            CountryCode:           marker.CountryCode,
            Mobile:                marker.Mobile,
            Active:                'Y'
        }
        const newM = await postMarker(guestDetails);
        helper.StopProcessing(btn);
        $('#modalMarker').modal('hide');
        props.bookingUserList.push({...newM,  UserID: newM.ID, UserType: "Marker"})
        props.fetchBookingDetails()
        swal.fire({
            icon: 'success',
            titleText: 'Marker added successfully!',
            showConfirmButton: false,
            timer: 1500,
        });
        
       } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
       }
    }

    return(
        <> 
            <div id="modalMarker" className="modal fade modal-responsive" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Marker</h5>
                            <button 
                                type="button" 
                                className="close" 
                                data-dismiss="modal" 
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <form className="kt-form" id="add-marker-form">
                                <div id="ModalMarkerHolder" className="kt-form kt-form--label-right" role="form">
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                        <div className="col-xl-6 col-lg-8">
                                            <input 
                                                id="txtMarkerName" 
                                                name="txtMarkerName" 
                                                type="text" 
                                                className="form-control" 
                                                value={marker.Name || ''}
                                                onChange={(event) => updateGuestDetails('Name', helper.camelCase(event.target.value))} 
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Mobile</label>
                                        <div className="col-xl-2 col-lg-3">
                                            <Select
                                                value={(props.mobileCodeList && props.mobileCodeList.length > 0 && marker.CountryCode) && {label: `${props.mobileCodeList.find(m => m.Code == marker.CountryCode).Name} ${props.mobileCodeList.find(m => m.Code == marker.CountryCode).Code}`, value: marker.CountryCode} || ''}
                                                onChange={(event) => updateGuestDetails('CountryCode', event.value)}
                                                name="ddlCountryCode"
                                                id="ddlCountryCode"
                                                className="ddlCountryCode"
                                                options={props.mobileCodeList.map(c => ({
                                                    label: `${c.Name} (${c.Code})`,
                                                    value: c.Code
                                                }))}
                                            />
                                        </div>
                                        <div className="col-xl-4 col-lg-5">
                                            <input 
                                                id="txtMarkerMobile" 
                                                name="txtMarkerMobile" 
                                                type="number" 
                                                className="form-control"
                                                value={marker.Mobile || ''}
                                                onChange={(event) => updateGuestDetails('Mobile', event.target.value)} 
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Email </label>
                                        <div className="col-xl-6 col-lg-8">
                                            <input 
                                                id="txtGuestEmail" 
                                                name="txtGuestEmail" 
                                                type="text" 
                                                className="form-control" 
                                                value={marker.Email || ''}
                                                onChange={(event) => updateGuestDetails('Email', event.target.value)} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="btnSaveMarker" name="btnSaveMarker" className="btn btn-brand margin-0 margin-r5" onClick={addGuestHandler}>Add</button>
                            <button type="button" className="btn btn-secondary margin-0 margin-r5" data-dismiss="modal">Close</button>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddMarker;