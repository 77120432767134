import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";


const getSportMasterAllUrl                                    = '/api/admin/sports/getSportMasterAll';
const getActiveSportsMasterUrl                                = '/api/admin/sports/getActiveSportsMaster';
const getViewSportsCheckInAllUrl                              = (fDate, tDate) => `/api/admin/sports/getViewSportsCheckInAll/${fDate}/${tDate}`;
const postSportsMasterUrl                                     = '/api/admin/sports/postSportsMaster';
const postFacilityMasterUrl                                   = '/api/admin/sports/postFacilityMaster';
const getActiveFacilityMasterBySportIDUrl                     = (sportID) => `/api/admin/sports/getActiveFacilityMasterBySportID/${sportID}`;
const getAllFacilityMasterBySportIDUrl                        = (sportID) => `/api/admin/sports/getAllFacilityMasterBySportID/${sportID}`;
const getViewSportTimeMasterAllUrl                            = '/api/admin/sports/getViewSportTimeMasterAll';
const postSportTimeMasterUrl                                  = '/api/admin/sports/postSportTimeMaster';
const postSportsTemplateUrl                                   = '/api/admin/sports/postSportsTemplate';
const getSportTemplateBySportTimeMasterIDAndFacilityIDUrl     = (sportTimeMasterID, facilityID) => `/api/admin/sports/getSportTemplateBySportTimeMasterIDAndFacilityID/${sportTimeMasterID}/${facilityID}`;
const getSportsPublishPageDataUrl                             = '/api/admin/sports/getSportsPublishPageData';    
const postSportsPublishUrl                                    = '/api/admin/sports/postSportsPublish';    
const getSportsPublishMasterDataUrl                           = '/api/admin/sports/getSportsPublishMasterData';    
const getSportsBookSheetUrl                                   = '/api/admin/sports/getSportsBookSheet';  
const getSportsBookingDetailsUrl                              = '/api/admin/sports/getSportsBookingDetails';  
const postMarkerUrl                                           = '/api/admin/sports/postMarker';
const postSportsBookUrl                                       = '/api/admin/sports/postSportsBook';
const getSportsSessionBookSheetUrl                            = '/api/admin/sports/getSportsSessionBookSheet';  
const deleteSportBookingUrl                                   = (id, comment) => `/api/admin/sports/deleteSportBooking/${id}/${comment}`
const getSportsCancellationSheetUrl                           = (fDate, tDate, sportID) => `/api/admin/sports/getSportsCancellationSheet/${fDate}/${tDate}/${sportID}`;
const getSportBookUrl                                         = '/api/admin/sports/getSportBook';  
const deleteSportsBlockUrl                                    = (id) => `/api/admin/sports/deleteSportsBlock/${id}`
const postSportsBlockUrl                                      = '/api/admin/sports/postSportsBlock';  
const getSportsBookUserForReportUrl                           = (fDate, tDate, facilityID) => `/api/admin/sports/getSportsBookUserForReport/${fDate}/${tDate}/${facilityID}`;
const sendNoShowSportEmailUrl                                 = '/api/admin/sports/sendNoShowSportEmail';  
const deleteSportsTemplateUrl                                 = '/api/admin/sports/deleteSportsTemplate';


export const getSportMasterAll = async () => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosGet(
    getSportMasterAllUrl,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getActiveSportsMaster = async () => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosGet(
    getActiveSportsMasterUrl,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getViewSportsCheckInAll = async (fDate, tDate) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getViewSportsCheckInAllUrl(fDate, tDate),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const postSportsMaster = async (sportDetails) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosPost(
    postSportsMasterUrl,
    { sportDetails },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const postFacilityMaster = async (facilities) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosPost(
    postFacilityMasterUrl,
    { facilities },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getActiveFacilityMasterBySportID = async (sportID) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getActiveFacilityMasterBySportIDUrl(sportID),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getAllFacilityMasterBySportID = async (sportID) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getAllFacilityMasterBySportIDUrl(sportID),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getViewSportTimeMasterAll = async () => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosGet(
    getViewSportTimeMasterAllUrl,
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const postSportTimeMaster = async (timeMasterDetails) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosPost(
    postSportTimeMasterUrl,
    { timeMasterDetails },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const postSportsTemplate = async (templateList) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosPost(
    postSportsTemplateUrl,
    { templateList },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getSportTemplateBySportTimeMasterIDAndFacilityID = async (sportTimeMasterID, facilityID) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getSportTemplateBySportTimeMasterIDAndFacilityIDUrl(sportTimeMasterID, facilityID),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getSportsPublishPageData = async() => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosGet( getSportsPublishPageDataUrl, { headers: { auth_token: `bearer ${token}` } } );
  return response;
}

export const postSportsPublish = async(data) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosPost( postSportsPublishUrl, {...data }, { headers: { auth_token: `bearer ${token}` } } );
  return response;
}


export const getSportsPublishMasterData = async(publishMasterId) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosGet( `${getSportsPublishMasterDataUrl}/${publishMasterId}`, { headers: { auth_token: `bearer ${token}` } } );
  return response;
}

export const getSportsBookSheet = async(date, sportID) => {
  const token = getAuthState('token');
  if(!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet( `${getSportsBookSheetUrl}/${date}/${sportID}`, { headers: { auth_token: `bearer ${token}` } });
  return response;
}
 
export const getSportsBookingDetails = async(id) => {
  const token = getAuthState('token');
  if(!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet( `${getSportsBookingDetailsUrl}/${id}`, { headers: { auth_token: `bearer ${token}` } });
  return response;
}

export const postMarker = async (marker) => {
  const token = getAuthState('token');
  if (!token) setTimeout(()=> { return }, 2000);
  
  const response = await axiosPost(
    postMarkerUrl,
    {  marker },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const postSportsBook = async (book, users, sportsPayment) => {
  const token = getAuthState('token');
  if (!token) setTimeout(()=> { return }, 2000);
  
  const response = await axiosPost(
    postSportsBookUrl,
    {  book, users, sportsPayment},
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getSportsSessionBookSheet = async(date, sportTemplateID) => {
  const token = getAuthState('token');
  if(!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet( `${getSportsSessionBookSheetUrl}/${date}/${sportTemplateID}`, { headers: { auth_token: `bearer ${token}` } });
  return response;
}

export const deleteSportBooking = async (id, comment) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosDelete(
    deleteSportBookingUrl(id, comment),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getSportsCancellationSheet = async (fDate, tDate, sportID) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getSportsCancellationSheetUrl(fDate, tDate, sportID),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getSportBook = async(date, facilityID) => {
  const token = getAuthState('token');
  if(!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet( `${getSportBookUrl}/${date}/${facilityID}`, { headers: { auth_token: `bearer ${token}` } });
  return response;
};

export const deleteSportsBlock = async (id) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosDelete(
    deleteSportsBlockUrl(id),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const postSportsBlock = async(books) =>{
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosPost( postSportsBlockUrl, { books }, { headers: { auth_token: `bearer ${token}` } } );
  return response;
}

export const getSportsBookUserForReport = async (fDate, tDate, facilityID) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getSportsBookUserForReportUrl(fDate, tDate, facilityID),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const sendNoShowSportEmail = async(data) =>{
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosPost( sendNoShowSportEmailUrl, { data }, { headers: { auth_token: `bearer ${token}` } } );
  return response;
}

export const deleteSportsTemplate = async(templateId) => {
  const token = getAuthState('token');
  if(!token) setTimeout(() => { return }, 2000);

  const response = await axiosDelete( `${deleteSportsTemplateUrl}/${templateId}`, { headers: { auth_token: `bearer ${token}` } });
  return response;
}