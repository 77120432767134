import { useState, useEffect } from "react";
import Select from 'react-select';
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { deleteSportsTemplate, getActiveFacilityMasterBySportID, getActiveSportsMaster, getSportTemplateBySportTimeMasterIDAndFacilityID, getViewSportTimeMasterAll, postSportsTemplate } from "../api/sportsAPI";
import RightSideDetails from "../global/rightSideDetails";

const SportsTemplate = (props) =>{

    const [sports,              setSports]                  = useState([])
    const [sessionType,         setSessionType]             = useState([{value: "Morning", label: "Morning"}, {value: "Afternoon", label: "Afternoon"}])
    const [rightSideDetails,    setRightSideDetails]        = useState(false);
    const [isInit,              setIsInit]                  = useState(true);
    const [timeMaster,          setTimeMaster]              = useState([]);
    const [timeMasterID,        setTimeMasterID]            = useState();
    const [bookingType,         setBookingType]             = useState();
    const [facilityID,          setFacilityID]              = useState();
    const [type,                setType]                    = useState({'SportID':  0});
    const [allFacilities,       setAllFacilities]           = useState([]);
    const [template,            setTemplate]                = useState({Session : sessionType[0].value, Active: "Y"});
    const [templateList,        setTemplateList]            = useState([]);
    const [isEdit,              setIsEdit]                  = useState(false);
    const [isEditable,          setIsEditable]              = useState(true);

    useEffect(() => {
        props.title('Sports Template', 'sports-template')
        fetchSports();
        fetchSportTimeMaster();
    },[])

    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer()
            $('#txtSlotFrom').timepicker({
                minuteStep: 10,
                defaultTime: ''
            });
    
            $('#txtSlotFrom').on('change',({target}) => dataHandler("SlotFrom", target.value) );
            $('#txtSlotTo').timepicker({
                minuteStep: 10,
                defaultTime: ''
            });
    
            $('#txtSlotTo').on('change',({target}) => dataHandler("SlotTo", target.value) );
        }else{
            setTemplate({Session : sessionType[0].value, Active: "Y"});
            setFacilityID();
            setTemplateList([]);
            setBookingType();
            setIsEdit(false);
            setIsEditable(true);

        }
    },[rightSideDetails])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable(type);        
        }
    }, [timeMaster]);

    useEffect(() => {
        if(type && !isInit){
            $('#tblTime').KTDatatable().destroy(); 
            fillDataTable(type);
        }
    },[type])
    
    useEffect(() => {
        if(timeMasterID && facilityID){
            fetchtSportTemplate(timeMasterID, facilityID);
            AllowSortable()
        }
    },[timeMasterID, facilityID])

    const updateTypeDetails = (field, value) => {   
        setType((d) => {
            return { ...d, [field]: value };
        });
    };

    const editHandler = (id) => {
        setIsEdit(true);
        const d = templateList.find(a => a.ID == id);
        setTemplate(d)
    }
    
    const deleteHandler = async(templateId) => {
        if(!templateId) return;

        swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
        try {
            const res = await deleteSportsTemplate(templateId);
            setTemplateList(res);       
                                                
            swal.fire({ toast: true, icon: 'success', position: 'bottom-end', showConfirmButton: false, timer: 2500,  
                    titleText: 'Template deleted successfully!', 
                    onOpen: () => swal.hideLoading()                                     
            });
        } catch (err) {
            swal.fire({ icon: 'error', titleText: 'Error!', text: err.message, buttonsStyling: false, confirmButtonClass: 'btn btn-brand'});
            return;
        }
    }

    const dataHandler = (field,value) => {
        setTemplate(d => ({...d, [field]: value}));
    }

    const fetchSports = async() => {
        try {
            const response = await getActiveSportsMaster();
            setSports([{ID: 0, Name: 'All'}, ...response])
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })
        }
    }
    
    const fetchtSportTemplate = async(sportTimeMasterID, facilityID) => {
        try {
            const res = await getSportTemplateBySportTimeMasterIDAndFacilityID(sportTimeMasterID, facilityID);
            res.length > 0 && setIsEditable(res[0].IsEditable);
            setTemplateList(res);
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })
        }
    }

    const  fetchSportTimeMaster = async() => {
        try {
            const response = await getViewSportTimeMasterAll();
            isInit ? setIsInit(false) : $('#tblTime').KTDatatable().destroy(); 
            setTimeMaster(response);
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })
        }
    }

    const fetchFacilityMasterBySportID = async(id) => {
        try {
            const master = await getActiveFacilityMasterBySportID(id)
            setAllFacilities(master);
            
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })
        }
    }
    
    function fillDataTable(t) {
        var TimeMasterList = [];
        if (t.SportID == 0 ) {
            TimeMasterList = timeMaster;
        }else{
            TimeMasterList = timeMaster.filter(i => i.SportID == t.SportID);
        }
        const dataTable = $('#tblTime').KTDatatable({
            data: {
                saveState: false,
                source: TimeMasterList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Title',
                    // title: 'Sports',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Logo && row.Logo != '') ? `<img src="${row.Logo}" alt="user-profile" height="50" width="50">` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span> 
                                    <span class="kt-user-card-v2__desc text-clip"> ${moment(moment(row.StartDate).format('YYYY-MM-DD')).isSame(moment(row.EndDate).format('YYYY-MM-DD')) ? moment(row.StartDate).format('DD MMM YYYY') : moment(row.StartDate).format('DD MMM YYYY') + ' - ' + moment(row.EndDate).format('DD MMM YYYY') } </span>
                                </div>
                            </div>`
                    }
                },
                {
                    field: 'Type',
                    template: function (row) {
                        if (row.Type == 'Weekend') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr30 padding-tb10">Weekend</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--info kt-badge--pill padding-lr30 padding-tb10"> Weekday </span>'
                        }
                    },
                    width: 180
                },{
                    field: 'ActiveAdmin',
                    title: 'Active Admin',
                    template: function (row) {
                        if (row.ActiveAdmin == 'Y') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 100,
                },
                {
                    field: 'ActiveUser',
                    title: 'Active User',
                    template: function (row) {
                        if (row.ActiveUser == 'Y') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 100,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblTime .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                fetchFacilityMasterBySportID(dataTable.dataSet[index].SportID);
                setTimeMasterID(dataTable.dataSet[index].ID);
                setBookingType(dataTable.dataSet[index].BookingType);
                setRightSideDetails(true); 
            }    
        });
    }
    
    const postTemplate = async() => {
        const btn  =  (!isEdit) ? $('#btnAdd') : $("#btnUpdate");
        const form = $('#addTemplateForm');

        form.validate({
            rules: {
                ddlFacility: {
                    required: true
                },  
                txtSlotFrom: {
                    required: true,
                },
                txtSlotTo: {
                    required: true
                }                        
            },
            messages: {
                ddlFacility: {
                    required: "Please select facility"
                },
                txtSlotFrom: {
                    required: "Please select slot"
                },
                txtSlotTo: {
                    required: "Please select slot"
                }
            }
        });

        if (!form.valid()) return;

        helper.StartProcessing(btn);
        try {
            if(!isEdit) template.Index = templateList.length + 1; 
            
            const formatedFromSlot = moment(`${moment().format('DD-MM-YYYY')} ${template.SlotFrom}`, 'DD-MM-YYYY hh:mm A')
            const formatedToSlot = moment(`${moment().format('DD-MM-YYYY')} ${template.SlotTo}`, 'DD-MM-YYYY hh:mm A')
            const formatedtemplateList = templateList.map(m => ({...m, SlotFrom: moment(`${moment().format('DD-MM-YYYY')} ${m.SlotFrom}`, 'DD-MM-YYYY hh:mm A'), SlotTo: moment(`${moment().format('DD-MM-YYYY')} ${m.SlotTo}`, 'DD-MM-YYYY hh:mm A')}));

            if(bookingType == 'Session'){
                if(moment(formatedFromSlot).isAfter(moment(formatedToSlot)) || formatedtemplateList.find((d) => d.ID != template.ID  && (moment(formatedFromSlot) < moment(d.SlotFrom) && moment(formatedToSlot) > moment(d.SlotTo)  || moment(formatedFromSlot).isBetween(d.SlotFrom, d.SlotTo) || moment(formatedToSlot).isBetween(d.SlotFrom, d.SlotTo) || moment(formatedFromSlot).isSame(d.SlotFrom) || moment(formatedToSlot).isSame(d.SlotTo)))){
                    swal.fire({
                        icon                  : 'warning',
                        titleText             : 'Date Conflict!',
                        buttonsStyling        : false,  
                        confirmButtonClass    : 'btn btn-brand',   
                    });
                    helper.StopProcessing(btn);
                    return
                }
            }else {
                if(templateList.find(f => f.ID != template.ID && f.SlotFrom == template.SlotFrom)){
                    swal.fire({ icon: "warning", titleText: 'Oops..', text: 'Slot already exist!', buttonsStyling: false, confirmButtonClass: "btn btn-brand"});
                    helper.StopProcessing(btn);
                    return
                }
            }

            saveBlocks([template]);                  
            
            helper.StopProcessing(btn);                  
            if (template.ID) {            
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Template updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3500,  
                    onOpen: () => swal.hideLoading()                                     
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Template added successfully!',
                    showConfirmButton: false,
                    timer: 3500,
                    onOpen: () => swal.hideLoading()    
                });
                
            }
            
            //Clear State ->
            clearAction();
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    //Sorting -->
    const AllowSortable =() =>{

        const config = (event, ui) =>{
            const ids = [];
            $('#table-block > tbody > .dragableRow').each((i,e) => ids.push( $(e).attr('data-id')));
            sortAndSave(ids);   
        }

        window.sortInit('#table-block tbody', config);    
    }

    const sortAndSave = (param)=> {
        if(param.length <= 0) return;      
        let templates = [];        

        setTemplateList( d => {
            const sortedTemplates = [];
            
            for (const [i,id] of Object.entries(param)) {
                let f = d.find( a => a.ID == id);
                if(!!f){
                    f.Index = parseInt(i)+1;                    
                    sortedTemplates.push(f);
                }
            }

            templates = sortedTemplates;
            return d;
        });
             
        if(templates.length > 0) saveBlocks(templates);                              
    }

    const clearAction = () => {       
        setTemplate({Session : sessionType[0].value, Active: "Y"});
        setIsEdit(false);
    } 

    const saveBlocks = async(template) => {
        if(template.length <= 0) return;
        try {
            swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
            const res = await postSportsTemplate(template.map(m => ({...m, SportTimeMasterID: timeMasterID, FacilityID: facilityID, SlotTo: m.SlotTo || ''})));
            setTemplateList(res);
                    
            swal.close()
        } catch (err) {
            swal.close()
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return; 
        }
    }

    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);        
    }
    
    const onCheck = async (value) => {
        saveBlocks([{...value, CheckBox: 'Y'}]);                  

    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor  padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet ">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0 padding-tb20">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0" style={{display: 'flex'}}>
                                        <div className="col-form-label col-1"></div>
                                        <label className="col-form-label col-2">Sport</label>
                                        <div className="kt-input-icon padding-r10 margin-lr20 col-7">
                                            <Select 
                                                id="ddlSport" 
                                                name="ddlSport"
                                                className="multi-custom"
                                                value={(sports && sports.length > 0 && type.SportID) && {label: sports.find((m) => m.ID == type.SportID).Name ,value: type.SportID} || {label: 'All', value: type.SportID}}
                                                onChange={(event) => updateTypeDetails('SportID', event.value)}
                                                options={sports.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />
                                        </div>
                                        <div className="col-form-label col-1"></div>
                                        <div className="kt-input-icon kt-input-icon--left col-7">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Master..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblTime" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && (<RightSideDetails title="Template" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form className="kt-form" id="addTemplateForm" >                
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10 bv-form" role="form">
                                                <div className="row">
                                                    <label className="col-form-label text-right padding-l40">Facility</label>
                                                    <div className="form-group col-xl-2 col-sm-2">
                                                        <div className="input-group ddlCoursePnl">
                                                            <Select 
                                                                id="ddlFacility" 
                                                                name="ddlFacility"
                                                                className="multi-custom width-95p"
                                                                value={(allFacilities && allFacilities.length > 0 && facilityID) && {label: allFacilities.find((m) => m.ID == facilityID).Name ,value: facilityID}}
                                                                onChange={(event) => setFacilityID(event.value)}
                                                                options={allFacilities.map(c => ({
                                                                    label: c.Name,
                                                                    value: c.ID
                                                                }))}
                                                            />
                                                        </div>
                                                    </div>
                                                    {isEditable && (
                                                        <>
                                                            <label className={`col-form-label text-right padding-l40 col-sm-${bookingType != "Session" ? '1' : ''}`}>{bookingType != "Session" ? 'Slot' : 'Slot From'}</label> 
                                                            <div className="form-group col-xl-2 col-sm-2">
                                                                <div className="input-group timepicker width-170  margin-r20">
                                                                    <input id="txtSlotFrom" name="txtSlotFrom" type="text" className="form-control" 
                                                                        readOnly={true} placeholder="Select time" 
                                                                        value={ template.SlotFrom || '' }                                               
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text height-38">
                                                                            <i className="la la-clock-o"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {bookingType == "Session" && 
                                                                <>
                                                                    <label className="col-form-label text-right">Slot To</label> 
                                                                    <div className="form-group col-xl-2 col-sm-2">
                                                                        <div className="input-group timepicker width-170  margin-r20">
                                                                            <input id="txtSlotTo" name="txtSlotTo" type="text" className="form-control" 
                                                                                readOnly={true} placeholder="Select time" 
                                                                                value={ template.SlotTo || '' }                                               
                                                                            />
                                                                            <div className="input-group-append">
                                                                                <span className="input-group-text height-38">
                                                                                    <i className="la la-clock-o"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            <label className={`col-form-label col-sm-${bookingType != "Session" ? '1' : ''} text-right`}>Session</label>
                                                            <div className="form-group col-xl-2 col-sm-2">
                                                                <div className="input-group ddlCoursePnl">
                                                                    <Select 
                                                                        id="ddlSession" 
                                                                        name="ddlSession"
                                                                        className="multi-custom width-95p"
                                                                        value={ template.Session && {label: sessionType.find((m) => m.label == template.Session ).label ,value: template.Session } || sessionType.find((m) => m.value[0] )}
                                                                        onChange={(event) => dataHandler('Session', event.value)}
                                                                        options={sessionType}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={`col-sm-${bookingType != "Session" ? '2' : '1'} padding-l20 `}>
                                                                {(!isEdit) ? (
                                                                    <button id="btnAdd" type="button" className={`btn btn-label-brand d-inline-block margin-0  height-38`} onClick={postTemplate} ><i className="la la-plus"></i>Add</button>
                                                                ):(
                                                                    <div className="btn-group  height-38">
                                                                        <button id="btnUpdate" type="button" className={`btn btn-label-success d-inline-block margin-0 `} onClick={postTemplate} > Update </button>
                                                                        <button id="btnCancel" type="button" className="btn btn-secondary" onClick={clearAction}> <i className="la la-times ml-1 font-16" > </i> </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>                                
                                            </div>
                                        </form>
                                        <table id="table-block" className="table table-bordered table-hover margin-b30">
                                            <thead className="light-grey-background">
                                                <tr>
                                                    <th width="10%" > Sr.No </th>
                                                    <th width="30%" > {bookingType} </th>
                                                    <th width="10%" > Session </th>
                                                    <th width="15%" > Active </th>
                                                    <th width="15%">  </th>
                                                </tr>
                                            </thead>
                                            <tbody className="ui-sortable">
                                                {templateList.length > 0  ?
                                                    templateList.map((t,i) => {
                                                        return (
                                                            <tr className="dragableRow" key={t.ID} data-id={t.ID}>
                                                                <td width="10%">{t.Index}</td>
                                                                <td width="10%">{t.SlotFrom}{t.SlotTo &&  `${' to ' + t.SlotTo}`} </td>
                                                                <td width="10%">{t.Session}</td>
                                                                <td className="padding-0" width="15%">
                                                                    <span className="kt-switch kt-switch--sm kt-switch--icon margin-lr10">
                                                                        <label className="margin-b0 padding-t5">
                                                                            <input 
                                                                                id="chkActive" 
                                                                                name="chkActive" 
                                                                                type="checkbox" 
                                                                                defaultChecked={t.Active == 'N' ? '' : 'checked'}
                                                                                onChange={(event) => onCheck({...t, Active: event.target.checked ? 'Y' : 'N'})} 
                                                                            />
                                                                            <span></span>
                                                                        </label>
                                                                    </span>
                                                                </td>
                                                                <td width="15%" className="text-center">
                                                                    {isEditable && (
                                                                        <>
                                                                            <button type="button" disabled={isEdit} className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-block-edit" onClick={() => editHandler(t.ID)}><i className="flaticon2-edit"></i></button>&nbsp;&nbsp;
                                                                            <button type="button" disabled={isEdit} className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-block-edit"onClick={() => deleteHandler(t.ID)}><i className="flaticon2-rubbish-bin-delete-button"></i></button> 
                                                                        </>
                                                                    )}  
                                                                </td>
                                                            </tr>
                                                        )
                                                    }):
                                                    (<tr>
                                                        <td colSpan={4}> 
                                                            <div className="padding-10"> 
                                                                <h5 className="text-center text-muted"> No Templates..! </h5> 
                                                            </div>
                                                        </td>
                                                    </tr>)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </RightSideDetails>
                            )}
                        </div>
                    </div>
                </div>           
            </div>
        </>
    )
}

export default SportsTemplate;